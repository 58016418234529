import axios from "axios";
import Cookies from "js-cookie";

const localhost = 'http://localhost:3001/'
const api = 'https://hdhr-apps-api.herokuapp.com/'

export const API = api;
export const SaveJobDescription = (object)=>{
    console.log(object)
    if(Cookies.get('user')){

    
const email = Cookies.get('user')
   let  resp = axios.post(API+'job-description-genie/save-job-description', {email:email, object});

if(resp === "error"){
    window.alert('Something went wrong. Please try again.')
} else {
    window.alert('Saved!')
}
    } else {
        window.alert('Please Login.')
    }

}

export const UpdateJobPost = (object)=>{
    console.log(object)
    if(Cookies.get('user')){

    
const email = Cookies.get('user')
   let  resp = axios.post(API+'job-description-genie/update-jd', {email:email, object});

if(resp === "error"){
    window.alert('Something went wrong. Please try again.')
} else {
    window.alert('Saved Changes!')
}
    } else {
        window.alert('Please Login.')
    }

}


export const ff = (object)=>{
    console.log(object)
    if(Cookies.get('user')){

    
const email = Cookies.get('user')
   let  resp = axios.post(API+'job-description-genie/update-jd', {email:email, object});

if(resp === "error"){
    window.alert('Something went wrong. Please try again.')
} else {
    window.alert('Saved Changes!')
}
    } else {
        window.alert('Please Login.')
    }

}