
import React, {useState, useEffect} from "react";
import { Badge, Form, Stack } from "react-bootstrap";
import { Box, Button, Divider, IconButton, Paper, TextField, Typography, Modal, Switch, Fade } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import DownloadDocxButton from "../components/DownloadDocx";
import Cookies from "js-cookie";

import { Circle } from "../components/Circle";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import LoadingModal from "../components/LoadingModal";
import { GenerateDocument } from "../classes/GenerateDocx";
import { API, SaveJobDescription } from "../classes/config/controller";
import { v4 as uuidv4 } from 'uuid';
import '../assets/css/style.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from "axios";

export const Writer = (props)=>{
const [loading, setLoading] = useState(true)
const [catArray, setCatArray] = useState([])
const [number, setNumber] = useState(0)
const [jobPosts, setJobPosts] = useState([])
const [job, setJob] = useState('')
const [showUser, setShowUser] = useState('')
const [salary, setSalary] = useState('')
const [showSalary, setShowSalary] = useState(false)
const [update, setUpdate] = useState(false)
const [openModal, setOpenModal] = useState(false);
const [showDelete, setShowDelete] = useState(false);
const [editorContent, setEditorContent] = useState('');
const[pt1, setPt1] = useState('')
const email = Cookies.get('user')
const[pt2, setPt2] = useState('')
useEffect(()=>{
  
    
    if(editorContent != ''){
       
        setOpenModal(false)
        setLoading(false)
    }
},[editorContent])

useEffect(()=>{
  
    
    if(pt1 !== '' && pt2 !== ''){
        setEditorContent(pt1 + pt2)
        setOpenModal(false)
        setLoading(false)
    }
},[pt1])

useEffect(()=>{
 
    if(pt1 !== '' && pt2 !== ''){
        setEditorContent(pt1 + pt2)
        setOpenModal(false)
        setLoading(false)
    }
},[pt2])

useEffect(()=>{

    const getData = async ()=>{
        console.log('running')
            const resp = await axios.post(API+'get-data',{email:email})

            if(resp === 'error'){
                window.alert('Something went wrong when loading user data. Please try again.')
            } else {
                console.log(resp.data)
                if(resp.data.length === 0 ){
                    setJobPosts([])
                } else {
                    setJobPosts(resp.data[0].jobPostings)

                }
               
             
           
            }
    }
    getData()
},[update])


const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorContent(data);
  };


function extractBodyContent(htmlString) {
    const bodyRegex = /<body[^>]*>([\s\S]*?)<\/body>/i;
    const match = bodyRegex.exec(htmlString);
    return match ? match[1] : "No body content found";
}


const handleBack = ()=>{
const check = window.confirm('If you choose to go back your job description will not be saved. Are you sure you would like to do this?')
if(check){
    
setLoading(true);
setCatArray([]);
setNumber(0);
setJob('');

}
}

const createDescription = ()=>{

   setOpenModal(true)
        const getData = async ()=>{
          let prompt = `You are a professional Job Description writer writing a job description for a ${job}.. Your job descriptions are highly detailed, compliant with the Fair Labor Standards Act (FLSA) and the Americans with Disabilities Act (ADA). They should be structured as follows: Job Title, Classification, Reports To, Date, Job Summary, Essential Functions (with percentage of time allocation), Working Conditions, Physical Demands, Qualifications (Required and Preferred), Knowledge and Skills, Technology Requirements, ADA Compliance Statement, FLSA Compliance Statement (including exemption category if applicable). Ensure the descriptions are thorough enough to be legally defensible while clearly outlining job expectations. Include a signature line for both employee and supervisor acknowledgment.

When adding a statement about required qualifications, do not require a college degree unless the position actually requires one. Instead, add "OR equivalent work experience" when appropriate. This ensures that valuable experience is considered without unnecessary degree requirements.

For overtime exempt classifications under FLSA, provide the exemption category, explain the decision, and advise reviewing the FLSA overtime exemption tests along with state and local requirements before final classification. Include a disclaimer stating the JD is not legal advice.

Emphasize highly detailed job descriptions and avoid requiring a college degree unless legally necessary. Follow this structure: Job Title, Classification, Reports To, Date, Job Summary, Essential Functions (with percentage of time allocation), Working Conditions, Physical Demands, Qualifications (Required and Preferred), Knowledge and Skills, Technology Requirements, ADA Compliance Statement, FLSA Compliance Statement (including exemption category if applicable).

Make assumptions based on typical industry standards when handling ambiguities or missing information.

Communicate in a helpful and confident manner with a conversational tone.

For this particular job I would ONLY like you to return these sections and these sections ONLY:
Qualifications (Required and Preferred; <ul> format), 
Knowledge and Skills(<ul> format), 
Technology Requirements(<ul> format), 
ADA Compliance Statement, 
FLSA Compliance Statement (including exemption category if applicable).,
Employee Acknowledgment(with signature lines)


Return the request in formatted HTML code. Return only HTML code in this request do not return any other text or comments. Make the title a p tag and font-weight of bold and font-size of 26px. If there are headings, make sure they are p tags with a font weight of bold and 22px font size. Then the section text just a normal font-weight with 16px font-size. Make sure the content is 100% of the width of the container. I would like it to be able to be printed as a docx and look professional. Do not include the tags: html,head,body,style,!DocType. Do not include a style section. If there are any styles make sure they are added inline. Add plenty of detail to each section.`;
           
                    const response = await axios.post(API+'api/create-data-description',{request:prompt ,location: 'HDHR', email:email, job:job, pt1:pt1})
                 if(response != 'error'){
                    console.log(response.data)
            
                  
                    try {
                        const result = response.data;
                      
                     
                    
              
                    
                        setPt2(result)
                        setOpenModal(false)

                
                 
                    } catch (error) {
                        setOpenModal(false)
                    console.error('Error parsing JSON response:', error);
                    alert('Something went wrong please try again.')
                    return;
                    }
                  
                //     let arr = JSON.parse(response.text)

                   
            
                  

                 } else{
                    alert('Something went wrong please try again.')
                 }
                }

               
                getData()
           
    
    
}

const createDescription2 = ()=>{

    setOpenModal(true)
         const getData = async ()=>{
           let prompt = `You are a professional Job Description writer, writing a job description for a ${job}. Your job descriptions are highly detailed, compliant with the Fair Labor Standards Act (FLSA) and the Americans with Disabilities Act (ADA). They should be structured as follows: Job Title, Classification, Reports To, Date, Job Summary, Essential Functions, Working Conditions, Physical Demands, Qualifications (Required and Preferred), Knowledge and Skills, Technology Requirements, ADA Compliance Statement, FLSA Compliance Statement (including exemption category if applicable). Ensure the descriptions are thorough enough to be legally defensible while clearly outlining job expectations. Include a signature line for both employee and supervisor acknowledgment.
 
 When adding a statement about required qualifications, do not require a college degree unless the position actually requires one. Instead, add "OR equivalent work experience" when appropriate. This ensures that valuable experience is considered without unnecessary degree requirements.
 
 For overtime exempt classifications under FLSA, provide the exemption category, explain the decision, and advise reviewing the FLSA overtime exemption tests along with state and local requirements before final classification. Include a disclaimer stating the JD is not legal advice.
 
 Emphasize highly detailed job descriptions and avoid requiring a college degree unless legally necessary. Follow this structure: Job Title, Classification, Reports To, Date, Job Summary, Essential Functions (with percentage of time allocation), Working Conditions, Physical Demands, Qualifications (Required and Preferred), Knowledge and Skills, Technology Requirements, ADA Compliance Statement, FLSA Compliance Statement (including exemption category if applicable).
 
 Make assumptions based on typical industry standards when handling ambiguities or missing information.
 
 Communicate in a helpful and confident manner with a conversational tone.
 
 For this particular job I would ONLY like you to return these sections and these sections ONLY:
 Job Title, 
 Classification, 
 Reports To, 
 Date(make sure and use today's date which is ${new Date()} formatted as mm/dd/yyyy), 
 Job Summary, 
 Essential Functions (with percentage of time allocation in a list format; example:<li><b>Develop and Implement Marketing Strategies (30%):</b> Create and execute comprehensive marketing plans that align with the company’s objectives, targeting key demographics and utilizing various marketing channels.</li> ), 
 Working Conditions,
 Physical Demands,
 
 
 Return the request in formatted HTML code. Return only HTML code in this request do not return any other text or comments. Make the title a p tag and font-weight of bold and font-size of 26px. If there are headings, make sure they are p tags with a font weight of bold and 22px font size. Then the section text just a normal font-weight with 16px font-size. Make sure the content is 100% of the width of the container. I would like it to be able to be printed as a docx and look professional. Do not include the tags: html,head,body,style,!DocType. Do not include a style section. If there are any styles make sure they are added inline. Add plenty of detail to each section.`;
            
                     const response = await axios.post(API+'api/create-data-description',{request:prompt})
                  if(response != 'error'){
                     console.log(response.data)
             
                   
                     try {
                         const result = response.data;
                       
                      
                     
               
                     
                         setPt1(result)                         
                         setOpenModal(false)
                         createDescription()
                 
                  
                     } catch (error) {
                         setOpenModal(false)
                     console.error('Error parsing JSON response:', error);
                     alert('Something went wrong please try again.')
                     return;
                     }
                   
                 //     let arr = JSON.parse(response.text)
 
                    
             
                   
 
                  } else{
                     alert('Something went wrong please try again.')
                  }
                 }
 
                
                 getData()
            
     
     
 }


if(loading) {
    return (
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

        <div style={{padding:'20px'}}>

            <Box>

            <Typography variant="button" sx={{fontSize:'16px'}}><b><Circle Title="1" Color="#0172C0"/>  Job title:</b></Typography><br/>
            <Typography sx={{marginTop:'10px'}} variant="subtitle2">Please enter the job title for which you would like to make a job description for.</Typography>
         <br></br>
         <Container>
         <Row xs={1} md={1}>
        <Col className="mt-1">
        <Form.Select
     
        onChange={(e)=>{
           let val = e.target.value
            if(val === 'userInput'){
                setShowUser(true)
            } else{
                setShowUser(false)
                setJob(val)
            }
        }}
        >
            <option value="---">---</option>
            <option value="userInput">Add New Job Title</option>
            {jobPosts.map(i=>{
                return <option>{i.jobName}</option>
            })}
        </Form.Select>
            </Col>
     
      </Row>
      {showUser && (
        <Row style={{marginTop:10}} xs={1} md={1}>
        <Col className="mt-1">
        <Form.Control 
        placeholder="Job Title"
        onChange={(e)=> setJob(e.target.value)} 
            />
            </Col>
     
      </Row>
      )

      }
         

     
      <Fade in={showSalary}>
            <Row style={{display:showSalary ? 'block':'none'}} className="mt-3"><Col>  
            <Form.Control 
            placeholder="Add Salary"
            onChange={(e)=> setSalary(e.target.value)} 
            />
            </Col>
            </Row>
    </Fade>

      </Container>
           


            </Box>
            
            <br></br>
            <div style={{textAlign:'right'}}>
            <Button sx={{marginTop:1, backgroundColor:'#0172C0'}} variant="contained" onClick={createDescription2}><b>Generate Job Description</b></Button>
            </div> 
          <LoadingModal
          Open={openModal}
         
          />
       
        </div>
        </Paper>
    )
} else {
    
    return (
        <div>
            <Box>
            <Typography variant="button" sx={{fontSize:'16px'}}><b><Circle Title="2" Color="#0172C0"/> Edit Job Description: </b><span style={{textTransform:'none'}}>Click the document below to make edits.</span></Typography><br/>
            <Typography sx={{marginTop:'10px'}} variant="subtitle2"></Typography>
        
            </Box>

            <Box sx={{marginBottom:'10px'}}>

            <Button variant="contained" onClick={handleBack}><ArrowBackIosNewIcon/> <b>BACK</b></Button>

            <DownloadDocxButton htmlContent={editorContent} docName={job} />

            <Button onClick={()=>{
                 let object = {
                    id:uuidv4(),
                    dateCreated: new Date(),
                    jobName: job,
                    content: editorContent
                    
                }
                SaveJobDescription(object);
                props.Action()
                
                }} sx={{marginLeft:'10px', float:'right', display:showDelete ? 'none':'inline'}}  variant="contained"><b>SAVE</b></Button>
            </Box>
            
        <Paper sx={{ p: 5, display: 'flex', flexDirection: 'column' }}>
              <div>
      <CKEditor
        editor={InlineEditor}
     
        data={editorContent}
        onChange={handleEditorChange}
      />
    
    </div>
    </Paper>
   
    </div>
)

}
}